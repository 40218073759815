<template>
  <div>
    <v-select
      v-if="renderSelect"
      id="vSelectCompanyName"
      ref="vSelectCompanyName"
      v-model="selected_companies"
      :multiple="multiple"
      :placeholder="placeholder"
      :options="all_companies"
      :class="validator && selected_companies && selected_companies.company_name ? '': 'danger-select' "
      label="company_name"
      @search="companySearch"
      @input="clearIfEmpty"
    />

    <b-modal
      id="companyModal"
      v-model="popupAddNewCompany"
      centered
      hide-footer
      title="Add New Company"
    >
      <div class="d-flex flex-column flex-gap-md">
        <div class="d-flex flex-row flex-gap-md align-items-center">
          <label style="width: 25ch;">Company Name</label>

          <b-form-input
            v-model="companyData.company_name"
            class="form-control-merge"
            placeholder="Input the name of the company"
          />
        </div>

        <div class="d-flex flex-row flex-gap-md align-items-center">
          <label style="width: 25ch;">UEN No</label>

          <b-form-input
            v-model="companyData.uen_no"
            class="form-control-merge"
            placeholder="Input the uen number of the company"
          />
        </div>

        <div class="d-flex justify-content-end mt-2">
          <b-button
            variant="primary"
            @click="createCompany"
          >Create Company</b-button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>

import { debounce } from 'lodash'
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    placeholder: { type: String, default: 'Select the company/companies you belong to' },
    stateErrors: { type: Array, default: null },
    multiple: { type: Boolean, default: false },
    allowAddNewCompany: { type: Boolean, default: false },
    validator: { type: Boolean, default: false },
    clearSelect: { type: Boolean, default: false },
    targetDb: { type: String, default: 'hq_dashboard' },
  },
  data() {
    return {
      isFirstLoad: true,
      all_companies: [],
      selected_companies: null,

      renderSelect: true,

      popupAddNewCompany: false,
      companyData: {},
    }
  },
  watch: {
    selected_companies(val) {
      if (this.$isNotEmpty(val)) {
        if (
          this.$isEmpty(val.company_uen_no)
            && val.company_name === 'Add new company'
        ) {
          this.popupAddNewCompany = true
        }
  
        if (!this.isFirstLoad) {
          if (!val.is_from_db && this.$isNotEmpty(val.id)) {
            this.createCompany(null, val)
          }
          this.$emit('company-selected', val)
        }
      }
    },
    clearSelect(val) {
      if (val) {
        this.selected_companies = this.multiple ? [] : {}
      }
    },
  },
  mounted() {
    // this.selected_companies = this.multiple ? [] : {}
  },

  methods: {
    companySearch(search, loading) {
      if (this.$isNotEmpty(search)) {
        loading(true)
        this.searchForCompany(loading, search, this)
      }
    },

    clearIfEmpty(search) {
      if (this.$isEmpty(search)) {
        this.$emit('company-selected', {})
      }
    },

    searchForCompany: debounce((loading, search, vm) => {

      vm.getEmployers(search, loading)
    }, 1000),

    getEmployers(query, loading = null) {
      this.$set(this, 'all_companies', [])
      const params = {}

      this.$http
        .get(`/api/company-search/${query}?params=${JSON.stringify(params)}`)
        .then(response => {
          const record = response.data
          if (record.success) {
            this.$set(this, 'all_companies', [])
            record.data.map(data => {
              const item = {
                company_name: data.company_name,
                id: data.id,
                company_uen_no: data.uen_no,
                is_from_db: data.is_from_db,
              }
              this.all_companies.push(item)
            })
          }

          if (this.$isEmpty(this.all_companies)) {
            const routeNameList = ['edit-event', 'applicant-tracking-system-edit', 'applicant-tracking-system-edit-specific']
            if (this.allowAddNewCompany && routeNameList.includes(this.$route.name)) {
              const option = {
                company_name: 'Add new company',
                id: null,
                company_uen_no: null,
              }
              this.$set(this, 'all_companies', [option])
  
              this.$set(this, 'renderSelect', false)
              setTimeout(() => {
                this.$set(this, 'renderSelect', true)
                setTimeout(() => {
                  document.querySelector('#vSelectCompanyName input.vs__search').focus()
                }, 5)
              }, 1)
            }
          }

          if (loading) {
            this.isFirstLoad = false
            loading(false)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },

    createCompany($event, val=null) {
      this.companyData.active = false
      this.companyData.verified = false

      if (this.$isNotEmpty(val)) {
        this.companyData.id = val.id
        this.companyData.uen_no = val.company_uen_no
        this.companyData.company_name = val.company_name
      }

      this.$http
        .post('/api/companies', {
          company_info: this.companyData,
        })
        .then(response => {
          const { success, output, message } = response.data
          if (success) {
            this.$toastSuccess('Operation Status', 'Company created successfully!')

            const company = {
              company_name: output.company_name,
              id: output.id,
              company_uen_no: output.uen_no,
              is_from_db: true,
            }

            this.$set(this, 'all_companies', [company])
            this.$set(this, 'selected_companies', company)
            this.$emit('company-selected', company)

            this.popupAddNewCompany = false
          } else {
            this.$toastDanger('Something went wrong', response.data.message)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },

  },
}
</script>
